import axios from "axios";
import {
  makeRequest,
  makeRequestWrapResult,
  pageLimit,
  PaginatedResponse,
  Result,
  smallPageLimit,
  SuccessResponse,
} from "./client";
import {
  DataTable,
  DataTableUploadEntry,
  DataType,
  OutputType,
} from "./data-tables-client";
import { User } from "./users-client";
import JSZip from "jszip";

export async function createDataTableUpload(
  dataTableUpload: DataTableUpload
): Promise<Result<DataTableUpload>> {
  return makeRequestWrapResult<DataTableUpload>(
    "POST",
    "/api/datatableuploads",
    null,
    dataTableUpload
  );
}

export async function getDataTableUploads(
  offset: number,
  limit: number = pageLimit
): Promise<PaginatedResponse<DataTableUpload> | null> {
  return makeRequest("GET", "/api/datatableuploads", {
    limit: limit,
    offset: offset * limit,
  });
}

export async function createUploadUrl(
  fileName: string,
  fileSize: number
): Promise<CreateUploadUrlResponse | null> {
  return await makeRequest(
    "POST",
    "/api/datatableuploads/create_upload_url",
    null,
    {
      fileName: fileName,
      fileType: "application/zip",
      fileSize: fileSize,
    }
  );
}

export async function zipFile(file: File): Promise<Blob> {
  if (file.type === "application/zip" || file.name.endsWith(".zip")) {
    return file;
  }

  const zip = new JSZip();
  zip.file(file.name, file);
  const zippedBlob = await zip.generateAsync({
    type: "blob",
    compression: "DEFLATE",
    compressionOptions: {
      level: 9,
    },
  });

  console.log(
    "Zipping completed, file sizes: before %d, after %d",
    file.size,
    zippedBlob.size
  );

  return zippedBlob;
}

export async function uploadFile(presignedUrl: string, file: Blob | File) {
  await fetch(presignedUrl, {
    body: file,
    method: "PUT",
    headers: {
      "Content-Type": "application/zip",
      "x-amz-acl": "private",
    },
  });
}

export async function readUpload(
  key: string,
  fileName: string,
  isNewDataTable: boolean
): Promise<UploadData | null> {
  return await makeRequest("POST", "/api/datatableuploads/read_upload", null, {
    fileName: fileName,
    key: key,
    isNewDataTable: isNewDataTable,
  });
}

export async function getUploadById(
  id: string
): Promise<DataTableUpload | null> {
  return makeRequest("GET", `/api/datatableuploads/${id}`);
}

export async function getUploadRawDownloadLink(
  id: string
): Promise<DownloadLink | null> {
  return makeRequest("GET", `/api/datatableuploads/${id}/download/raw`);
}

export async function getUploadProcessedDownloadLink(
  id: string
): Promise<DownloadLink | null> {
  return makeRequest("GET", `/api/datatableuploads/${id}/download/processed`);
}

export async function importUpload(
  id: string
): Promise<SuccessResponse | null> {
  return makeRequest("POST", `/api/datatableuploads/${id}/request_import`);
}

export async function validateUpload(
  id: string
): Promise<SuccessResponse | null> {
  return makeRequest("POST", `/api/datatableuploads/${id}/request_validate`);
}

export async function getUploadEntries(
  id: string,
  failedOnly: boolean | null,
  page: number,
  limit: number = smallPageLimit
): Promise<PaginatedResponse<DataTableUploadEntry> | null> {
  const params = {
    limit: limit,
    offset: page * limit,
  };
  if (failedOnly != null) {
    params["failed_only"] = `${failedOnly}`;
  }

  return makeRequest("GET", `/api/datatableuploads/${id}/entries`, params);
}

export async function updateUploadEntries(
  id: string,
  updatedReqs: UpdateEntryRequest[]
): Promise<PaginatedResponse<DataTableUploadEntry> | null> {
  return makeRequest(
    "POST",
    `/api/datatableuploads/${id}/entries`,
    null,
    updatedReqs
  );
}

export async function abandonUpload(
  id: string
): Promise<SuccessResponse | null> {
  return makeRequest("POST", `/api/datatableuploads/${id}/abandon`);
}

export interface DownloadLink {
  url: string;
}

export interface UpdateEntryRequest {
  entryId: string;
  value: { [key: string]: any };
}

export enum DataTableUploadStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  PENDING_EXPORT = "PENDING_EXPORT",
  EXPORTING = "EXPORTING",
  PROCESSED = "PROCESSED",
  FAILED = "FAILED",
  REVERTED = "REVERTED",
  CANCELLED = "CANCELLED",
  IMPORTED = "IMPORTED",
}

export enum DataTableUploadMode {
  APPEND = "APPEND",
  REPLACE = "REPLACE",
}

export interface DataTableUpload {
  dataTableUploadId: string | null;
  fileName: string;
  fileKey: string;
  dataTableId: string;
  dataTable: DataTable | null;
  uploadUserId?: string;
  uploadUser?: User | null;
  status: DataTableUploadStatus;
  uploadMode: DataTableUploadMode;
  failureMessage: string | null;
  rowCount: number;
  rowFailureCount: number;
  failureCount: number;
  createdAtUtc: Date;
  updatedAtUtc: Date;
}

export interface CreateUploadUrlResponse {
  uploadUrl: string;
  key: string;
}

export interface UploadData {
  key: string;
  rows: any[];
  typeByColumn: {
    [key: string]: DataType;
  };
  indexByColumn: {
    [key: string]: number;
  };
  tables: DataTable[];
}

export function parseUploadEntryValidationErrors(errors: string[] | null): {
  [key: string]: string[];
} {
  return (errors || []).reduce((obj, err) => {
    err
      .substring(err.lastIndexOf("[") + 1, err.lastIndexOf("]"))
      .split(",")
      .forEach((col) => {
        obj[col.toLowerCase()] = (obj[col.toLowerCase()] || []).concat(err);
      });

    return obj;
  }, {});
}
