export async function waitUntil(
  conditionFn: () => Promise<boolean>,
  interval: number = 1000,
  maxTime: number | null = null,
  exitOnError: boolean = false
): Promise<void> {
  const start = Date.now();
  return new Promise((resolve, reject) => {
    const checkCondition = () => {
      if (maxTime && Date.now() - start > maxTime) {
        reject(new Error("Condition not met within max time"));
        return;
      }

      conditionFn()
        .then((result) => {
          if (result) {
            resolve();
            return;
          }

          setTimeout(checkCondition, interval);
        })
        .catch((error) => {
          if (exitOnError) {
            reject(error);
            return;
          }

          setTimeout(checkCondition, interval);
        });
    };

    setTimeout(checkCondition, interval);
  });
}
