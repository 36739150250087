import classNames from "classnames";
import { FormControl, FormLabel, Input, Select } from "@chakra-ui/react";

import styles from "./S3ConnectionForm.module.scss";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  getAzureContainers,
  getAzureContainersByConnection,
} from "../api/connections-client";

function extractField(connStr: string, field: string, def: string): string {
  const value = connStr.split(`${field}=`);
  if (value.length < 2) {
    return def;
  }

  const extracted = value[1].split(";")[0];
  if (extracted === "") return def;

  return extracted;
}

function BlobConnectionForm({
  className,
  containerName,
  accountName,
  accountKey,
  endpointSuffix,
  defaultEndpointsProtocol,
  placeholderConnString,
  connectionId,
  setAccountName,
  setAccountKey,
  setEndpointSuffix,
  setContainerName,
  setDefaultEndpointsProtocol,
  setValid,
}: {
  className: string | null;
  containerName: string;
  accountName: string;
  accountKey: string;
  endpointSuffix: string;
  defaultEndpointsProtocol: string | null;
  placeholderConnString: string | null;
  connectionId: string | null;
  setAccountName: (string) => void;
  setAccountKey: (string) => void;
  setEndpointSuffix: (string) => void;
  setContainerName: (string) => void;
  setDefaultEndpointsProtocol: (string) => void;
  setValid: (bool) => void;
}) {
  const [connectionString, setConnectionString] = useState(
    placeholderConnString || ""
  );
  const containers = useQuery(
    `${accountName}-${accountKey}-${endpointSuffix}-${defaultEndpointsProtocol}-${containerName}`,
    () =>
      connectionId
        ? getAzureContainersByConnection(connectionId)
        : getAzureContainers(
            accountName,
            accountKey,
            endpointSuffix,
            defaultEndpointsProtocol || ""
          )
  );

  const hasContainers = (containers.data?.length || 0) > 0;
  setValid(
    accountName !== "" &&
      accountKey !== "" &&
      endpointSuffix !== "" &&
      defaultEndpointsProtocol !== "" &&
      containerName !== ""
  );

  if (hasContainers && containerName === "") {
    setContainerName(containers.data!![0]);
  }

  return (
    <FormControl className={classNames(styles.container, className)}>
      <FormLabel htmlFor="connectionString">Connection String</FormLabel>
      <Input
        className={styles.input}
        id="connectionString"
        type="text"
        value={connectionString}
        onChange={(e) => {
          const connStr = e.currentTarget.value;
          if (connStr.indexOf("*") === -1) {
            setAccountName(extractField(connStr, "AccountName", ""));
            setAccountKey(extractField(connStr, "AccountKey", ""));
            setEndpointSuffix(
              extractField(connStr, "EndpointSuffix", "core.windows.net")
            );
            setDefaultEndpointsProtocol(
              extractField(connStr, "DefaultEndpointsProtocol", "https")
            );
          }
          setConnectionString(connStr);
        }}
        placeholder="Connection String"
        required={true}
      />
      <FormLabel className={styles.label} htmlFor="container">
        Container
      </FormLabel>
      <Select
        id="container"
        disabled={!hasContainers}
        value={containerName}
        className={styles.input}
        placeholder={hasContainers ? undefined : "Check connection settings"}
        onChange={(e) => setContainerName(e.currentTarget.value)}
      >
        {(containers.data || []).map((b) => (
          <option key={b} value={b}>
            {b}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

BlobConnectionForm.defaultProps = {
  className: null,
};
export default BlobConnectionForm;
