import { Button, Checkbox, Flex } from "@chakra-ui/react";
import styles from "./SelectDataSource.module.scss";
import SimpleTable, {
  TableColumnDataType,
} from "../../../components/SimpleTable";
import { DataTableUploadMode } from "../../../api/data-table-uploads-client";
import { DataTable, DataType } from "../../../api/data-tables-client";
import { useState } from "react";
import classNames from "classnames";

export default function SelectDataSource({
  dataTable,
  onCreate,
  isLoading,
  uploadFileRows,
  uploadFileColumnTypes,
  onCancel,
}: {
  dataTable: DataTable;
  dataTableId: string;
  onCreate: (DataTable, UploadMode) => void;
  isLoading: boolean;
  uploadFileRows: any[];
  uploadFileColumnTypes: {
    [key: string]: DataType;
  };
  onCancel: () => void;
}) {
  const [uploadMode, setUploadMode] = useState<DataTableUploadMode>(
    DataTableUploadMode.APPEND
  );

  const fileColumns = Object.keys(uploadFileColumnTypes);

  return (
    <Flex
      className={classNames(styles.selectDataSourceContainer, styles.container)}
    >
      <SimpleTable
        className={styles.previewTable}
        editable={false}
        columns={fileColumns.map((col) => ({
          id: col,
          name: col,
          dataType: TableColumnDataType.Text,
        }))}
        data={uploadFileRows}
        isLoading={false}
      />

      {dataTable?.latestUploadKey != null && !dataTable?.appendOnly && (
        <>
          <Checkbox
            className={styles.input}
            onChange={(e) => {
              if (e.target.checked) setUploadMode(DataTableUploadMode.APPEND);
            }}
            isChecked={uploadMode === DataTableUploadMode.APPEND}
          >
            Append uploaded data
          </Checkbox>
          <Checkbox
            className={styles.input}
            onChange={(e) => {
              if (e.target.checked) setUploadMode(DataTableUploadMode.REPLACE);
            }}
            isChecked={uploadMode === DataTableUploadMode.REPLACE}
          >
            Overwrite uploaded data
          </Checkbox>

          <p className={styles.disclaimer}>
            {uploadMode === DataTableUploadMode.APPEND
              ? "By choosing to append data, new entries will be appended and any existing entries will be updated. If an entry already exists from a previous upload but not in the current file it will retain its current value."
              : "By choosing to overwite uploaded data, all data will be deleted and only the data in the current file will exist for this data source."}
          </p>
        </>
      )}

      <Flex className={styles.btnContainer}>
        <Button
          onClick={onCancel}
          w={130}
          fontWeight="normal"
          variant="outline"
          size="md"
        >
          Cancel
        </Button>
        <Button
          w={130}
          isLoading={isLoading}
          disabled={dataTable == null || uploadMode == null}
          onClick={() => onCreate(dataTable, uploadMode)}
          colorScheme="primaryScheme"
          color="white"
        >
          Next
        </Button>
      </Flex>
    </Flex>
  );
}

SelectDataSource.defaultProps = {
  dataTableId: "",
};
