import {
  TableContainer,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Table,
  Input,
  Checkbox,
  Skeleton,
  Flex,
  Tooltip,
  Box,
  Select,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import classNames from "classnames";
import React from "react";
import { ReactNode } from "react";
import { useQuery } from "react-query";
import { PaginatedInfo, PaginatedResponse } from "../api/client";
import Pagination from "react-js-pagination";

import styles from "./SimpleTable.module.scss";

type OnChange = (
  row: any,
  idx: number,
  column: TableColumn,
  value: any
) => void;

export enum TableColumnDataType {
  Number = "NUMBER",
  Text = "TEXT",
  Boolean = "BOOLEAN",
  Date = "DATE",
}

const dateFormatter = new Intl.DateTimeFormat("en-AU", {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
});
type ColumnRenderer = (
  row: any,
  column: TableColumn,
  value: any,
  onChange: (value: any) => void
) => ReactNode | null;

type ColumnContainerProvider = (
  row: any,
  column: TableColumn,
  value: any,
  renderedColumn: ReactNode
) => ReactNode | null;

function renderColumn(
  row: any,
  key: string,
  columnRenderer: ColumnRenderer | null,
  columnContainerProvider: ColumnContainerProvider,
  col: TableColumn,
  value: any,
  onChange: (value: any) => void | null,
  editable: boolean,
  onClick: () => void | null,
  loading: boolean,
  readOnlyColumns: string[] | null,
  colWidth: number | null,
  inputClassName: string
): ReactNode {
  if (columnRenderer) {
    const rendered = columnRenderer(row, col, value, onChange);
    if (rendered) {
      return (
        <Td
          width={colWidth || "inherit"}
          onClick={onClick}
          key={key}
          className={styles.td}
        >
          {columnContainerProvider(
            row,
            col,
            value,
            <Skeleton isLoaded={!loading}>{rendered}</Skeleton>
          )}
        </Td>
      );
    }
  }
  if (
    readOnlyColumns != null &&
    readOnlyColumns.indexOf(col.id) !== -1 &&
    col.dataType !== TableColumnDataType.Boolean
  ) {
    return (
      <Td
        width={colWidth || "inherit"}
        onClick={onClick}
        className={styles.readOnlyCell}
        key={key}
      >
        {columnContainerProvider(
          row,
          col,
          value,
          <Skeleton isLoaded={!loading} title={value}>
            {value}
          </Skeleton>
        )}
      </Td>
    );
  }

  switch (col.dataType) {
    case TableColumnDataType.Number:
      if (editable) {
        return (
          <Td
            width={colWidth || "inherit"}
            onClick={onClick}
            key={key}
            className={styles.td}
          >
            {columnContainerProvider(
              row,
              col,
              value,
              <Skeleton isLoaded={!loading}>
                <NumberInput
                  className={classNames(styles.input, inputClassName)}
                  onChange={(e) => onChange && onChange(e)}
                  value={value}
                >
                  <NumberInputField />
                </NumberInput>
              </Skeleton>
            )}
          </Td>
        );
      }
      break;
    case TableColumnDataType.Text:
      if (editable) {
        return (
          <Td
            width={colWidth || "inherit"}
            onClick={onClick}
            key={key}
            className={styles.td}
          >
            {columnContainerProvider(
              row,
              col,
              value,
              <Skeleton isLoaded={!loading}>
                <Input
                  className={classNames(styles.input, inputClassName)}
                  onChange={(e) => onChange && onChange(e.target.value)}
                  value={value}
                />
              </Skeleton>
            )}
          </Td>
        );
      }
      break;
    case TableColumnDataType.Boolean:
      return (
        <Td
          width={colWidth || "inherit"}
          key={key}
          className={styles.td}
        >
          {columnContainerProvider(
            row,
            col,
            value,
            <Skeleton isLoaded={!loading}>
              <Checkbox
                disabled={
                  !editable ||
                  (readOnlyColumns != null &&
                    readOnlyColumns.indexOf(col.id) !== -1)
                }
                className={classNames(styles.checkboxInput, inputClassName)}
                onChange={(e) => onChange && onChange(e.target.checked)}
                isChecked={value}
              />
            </Skeleton>
          )}
        </Td>
      );
    case TableColumnDataType.Date:
      if (editable) {
        return (
          <Td
            width={colWidth || "inherit"}
            onClick={onClick}
            key={key}
            className={styles.td}
          >
            {columnContainerProvider(
              row,
              col,
              value,
              <Skeleton isLoaded={!loading}>
                <Input
                  className={classNames(styles.input, inputClassName)}
                  onChange={(e) => onChange && onChange(e.target.value)}
                  value={value}
                />
              </Skeleton>
            )}
          </Td>
        );
      } else if (value instanceof Date) {
        return (
          <Td
            width={colWidth || "inherit"}
            onClick={onClick}
            key={key}
            className={styles.td}
          >
            {columnContainerProvider(
              row,
              col,
              value,
              <Skeleton isLoaded={!loading}>
                {dateFormatter
                  .format(value)
                  .replace(" am", "am")
                  .replace(" pm", "pm")}
              </Skeleton>
            )}
          </Td>
        );
      }
      break;
  }

  return columnContainerProvider(
    row,
    col,
    value,
    <Td
      width={colWidth || "inherit"}
      onClick={onClick}
      key={key}
      className={styles.td}
    >
      <Skeleton isLoaded={!loading} title={value}>
        {value}
      </Skeleton>
    </Td>
  );
}

interface PageSize {
  page: number;
  size: number;
}

export interface TableColumn {
  id: string;
  name: string;
  dataType: string;
  width?: number;
}

export function PaginatedSimpleTable<T>({
  columns,
  getData,
  dataName,
  transform,
  className,
  inputClassName,
  onChange,
  addBtn,
  columnRenderer,
  columnContainerProvider,
  editable,
  onRowClick,
  actions,
  colWidths,
  disablePagination,
  refreshInterval,
  setPaginatedInfo,
}: {
  columns: TableColumn[];
  getData: (pageSize, offset: number) => Promise<PaginatedResponse<T> | null>;
  dataName: string;
  transform: (resp: PaginatedResponse<T> | null) => any[];
  className: string;
  inputClassName: string;
  onChange: OnChange | null;
  addBtn: ReactNode | null;
  columnRenderer: ColumnRenderer | null;
  columnContainerProvider: ColumnContainerProvider | null;
  editable: boolean;
  onRowClick: ((row: any) => void) | null;
  actions: ((row: any) => ReactNode) | null;
  colWidths: (number | null)[] | null;
  disablePagination: boolean;
  refreshInterval: number;
  setPaginatedInfo: React.Dispatch<React.SetStateAction<PaginatedInfo | null>>;
}) {
  const [pageSize, setPageSize] = React.useState<PageSize>({
    page: 0,
    size: 10,
  });

  const { data, isLoading } = useQuery(
    [dataName, pageSize.page, pageSize.size],
    () => getData(pageSize.page, pageSize.size),
    {
      refetchInterval: refreshInterval,
    }
  );

  if (setPaginatedInfo && data && data.count >= 0) {
    setPaginatedInfo(data);
  }

  return (
    <Flex className={styles.paginatedTableContainer}>
      <SimpleTable
        columns={columns}
        data={transform(data || null)}
        className={className}
        inputClassName={inputClassName}
        onChange={onChange}
        addBtn={addBtn}
        columnRenderer={columnRenderer}
        columnContainerProvider={columnContainerProvider}
        editable={editable}
        onRowClick={onRowClick}
        actions={actions}
        colWidths={colWidths}
        isLoading={isLoading}
      />
      {data != null && data.count > 0 && !disablePagination && (
        <Flex
          alignItems="center"
          gap="6"
          className={styles.paginationContainer}
        >
          <Flex alignItems="center" gap="2">
            <span className={styles.rowsLabel}>Rows</span>
            <Select
              id="connection"
              className={styles.select}
              value={pageSize.size}
              required={true}
              onChange={(e) => {
                setPageSize({
                  page: 0,
                  size: parseInt(e.currentTarget.value),
                });
              }}
            >
              {[10, 20, 50, 100].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex alignItems="center" gap="2">
            <span className={styles.rowsLabel}>Page</span>
            <Pagination
              innerClass={classNames("pagination", styles.paginationList)}
              hideDisabled={true}
              itemClass={styles.paginationItem}
              activeClass={styles.activePage}
              activePage={pageSize.page + 1}
              itemsCountPerPage={pageSize.size}
              totalItemsCount={data.count}
              pageRangeDisplayed={5}
              onChange={(offset) =>
                setPageSize({
                  page: offset - 1,
                  size: pageSize.size,
                })
              }
            />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

PaginatedSimpleTable.defaultProps = {
  addBtn: null,
  onChange: null,
  columnRenderer: null,
  columnContainerProvider: null,
  onRowClick: null,
  actions: null,
  isLoading: false,
  colWidths: null,
  disablePagination: false,
  refreshInterval: 3000,
  setPaginatedInfo: null,
  inputClassName: "",
};

function SimpleTable<T>({
  columns,
  data,
  className,
  inputClassName,
  onChange,
  addBtn,
  columnRenderer,
  columnContainerProvider,
  editable,
  onRowClick,
  actions,
  isLoading,
  readOnlyColumns,
  colWidths,
}: {
  columns: TableColumn[];
  data: any[];
  className: string;
  inputClassName: string;
  onChange: OnChange | null;
  addBtn: ReactNode | null;
  columnRenderer: ColumnRenderer | null;
  columnContainerProvider: ColumnContainerProvider | null;
  editable: boolean;
  onRowClick: ((row: any) => void) | null;
  actions: ((row: any) => ReactNode) | null;
  isLoading: boolean;
  readOnlyColumns: string[] | null;
  colWidths: (number | null)[] | null;
}) {
  const isEmptyClass =
    data.length === 0 && Object.keys(columns).length === 0
      ? styles.disableBorder
      : null;
  return (
    <Flex className={styles.tableContainer}>
      <TableContainer
        className={classNames(styles.table, className, isEmptyClass)}
      >
        <Table variant="simple">
          <Thead className={styles.header}>
            <Tr>
              {columns
                .concat(
                  actions != null
                    ? [
                      {
                        id: "actions",
                        name: "Actions",
                        dataType: TableColumnDataType.Text,
                      },
                    ]
                    : []
                )
                .map((col, colIdx) => (
                  <Th
                    width={
                      col.width ||
                      (colWidths && colWidths.length > colIdx
                        ? colWidths[colIdx]
                        : null) ||
                      "inherit"
                    }
                    className={classNames(styles.th, styles[col.dataType])}
                    key={col.id}
                    title={col.name}
                  >
                    {col.name}
                  </Th>
                ))}
            </Tr>
          </Thead>
          <Tbody className={styles.tableBody}>
            {data.map((row, rowIdx) => (
              <Tr
                key={rowIdx}
                className={classNames(onRowClick ? styles.clickable : null)}
              >
                {columns.map((col, colIdx) =>
                  renderColumn(
                    row,
                    `${rowIdx}-${col.name}`,
                    columnRenderer,
                    columnContainerProvider ||
                    ((row, col, value, renderedColumn) => renderedColumn),
                    col,
                    row[col.id],
                    (value: any) =>
                      onChange && onChange(row, rowIdx, col, value),
                    editable,
                    () => onRowClick && onRowClick(row),
                    isLoading,
                    readOnlyColumns,
                    colWidths && colWidths.length > colIdx
                      ? colWidths[colIdx]
                      : null,
                    inputClassName
                  )
                )}
                {actions && (
                  <Td className={styles.actions}>
                    <Skeleton
                      height={isLoading ? "20px" : "inherit"}
                      isLoaded={!isLoading}
                    >
                      {actions(row)}
                    </Skeleton>
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
        {addBtn && <Box className={styles.spacing}>{addBtn}</Box>}
      </TableContainer>
    </Flex>
  );
}

SimpleTable.defaultProps = {
  addBtn: null,
  onChange: null,
  columnRenderer: null,
  columnContainerProvider: null,
  onRowClick: null,
  actions: null,
  isLoading: false,
  readOnlyColumns: null,
  colWidths: null,
  inputClassName: "",
};
export default SimpleTable;
