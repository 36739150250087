import { Button, Flex, Heading, Spinner } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  PaginatedSimpleTable,
  TableColumnDataType,
} from "../components/SimpleTable";

import styles from "./Roles.module.scss";
import { getMe } from "../api/users-client";
import { Role, getRoles } from "../api/roles-client";
import { canView } from "../util/permission";

function Roles() {
  const { data: user, isLoading: isLoadingUser } = useQuery("me", getMe);

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  if (isLoadingUser) {
    return (
      <Flex className={styles.container}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  return (
    <Flex className={styles.container}>
      <Heading className={styles.title}>Roles</Heading>
      {user != null &&
        canView(
          user,
          user?.permissions?.canReadRoles || false,
          ""
        ) && (
          <Flex className={styles.createBtnContainer}>
            <Button
              onClick={() => navigate("/roles/new")}
              colorScheme="primaryScheme"
              color="white"
            >
              Create
            </Button>
          </Flex>
        )}
      <Flex className={styles.usersTableContainer}>
        <PaginatedSimpleTable
          className={styles.table}
          editable={false}
          columns={[
            {
              id: "name",
              name: "Name",
              dataType: TableColumnDataType.Text,
            },
          ]}
          onRowClick={(role: any) => navigate(`/roles/edit/${role.roleId}`)}
          getData={getRoles}
          dataName="roles"
          transform={(role) =>
            (role?.results || [null, null, null]).map((role: Role | null) => {
              return {
                roleId: role?.roleId || "-",
                name: role?.name || "-",
              };
            })
          }
          columnRenderer={null}
        />
      </Flex>
    </Flex>
  );
}

export default Roles;
