import { Tab } from "react-bootstrap";
import { TableColumnDataType } from "../components/SimpleTable";
import {
  makeRequest,
  pageLimit,
  PaginatedResponse,
  smallPageLimit,
  SuccessResponse,
} from "./client";
import { Connection } from "./connections-client";
import { DataTableUpload } from "./data-table-uploads-client";

export async function createDataTable(
  dataTable: DataTable
): Promise<DataTable | null> {
  dataTable.columns = dataTable.columns.sort((a, b) => a.index - b.index);
  return makeRequest<DataTable>("POST", "/api/datatables", null, dataTable);
}

export async function updateDataTable(
  dataTable: DataTable
): Promise<DataTable | null> {
  dataTable.columns = dataTable.columns.sort((a, b) => a.index - b.index);
  return makeRequest<DataTable>(
    "PUT",
    `/api/datatables/${dataTable.dataTableId}`,
    null,
    dataTable
  );
}

export async function getDataTables(
  offset: number,
  limit: number = pageLimit
): Promise<PaginatedResponse<DataTable> | null> {
  return makeRequest("GET", "/api/datatables", {
    limit: limit,
    offset: offset * limit,
  });
}

export async function getDataTable(id: string): Promise<DataTable | null> {
  return makeRequest<DataTable>("GET", `/api/datatables/${id}`);
}

export async function duplicateDataTable(
  id: string
): Promise<DataTable | null> {
  return makeRequest<DataTable>("POST", `/api/datatables/${id}/duplicate`);
}

export async function deleteDataTable(
  id: string
): Promise<SuccessResponse | null> {
  return makeRequest<SuccessResponse>("DELETE", `/api/datatables/${id}`);
}

export async function getDataTableDataLink(
  id: string
): Promise<UrlResponse | null> {
  return makeRequest("GET", `/api/datatables/${id}/download`);
}

export async function getDataTableTemplateLink(
  id: string
): Promise<UrlResponse | null> {
  return makeRequest("GET", `/api/datatables/${id}/template`);
}

export async function getDataTableCurrentEntries(
  id: string,
  page: number,
  limit: number = smallPageLimit
): Promise<PaginatedResponse<DataTableUploadEntry> | null> {
  return makeRequest<PaginatedResponse<DataTableUploadEntry>>(
    "GET",
    `/api/datatables/${id}/current_entries`,
    {
      limit: limit,
      offset: page * limit,
    }
  );
}

export async function getDataTableUploadHistory(
  id: string,
  page: number,
  limit: number = smallPageLimit
): Promise<PaginatedResponse<DataTableUpload> | null> {
  return makeRequest<PaginatedResponse<DataTableUpload>>(
    "GET",
    `/api/datatables/${id}/upload_history`,
    {
      limit: limit,
      offset: page * limit,
    }
  );
}

export async function generateDataTableScript(
  id: string,
  message: string
): Promise<GenerateDataTableScriptResponse | null> {
  return makeRequest<GenerateDataTableScriptResponse>(
    "POST",
    `/api/datatables/${id}/generate_script`,
    null,
    {
      message: message
    }
  );
}

export enum OutputType {
  CSV = "CSV",
  JSON = "JSON",
}

export enum OutputMode {
  FULL = "FULL",
  DELTA = "DELTA",
}

export interface UrlResponse {
  url: string;
}

export interface GenerateDataTableScriptResponse {
  script: string;
}

export interface DataTable {
  dataTableId: string;
  name: string;
  description: string;
  outputType: OutputType;
  outputPath: string;
  connectionId: string;
  connection?: Connection | null;
  columns: DataTableColumn[];
  latestUploadKey: string | null;
  templateKey: string | null;
  appendOnly: boolean;
  appendTime: boolean;
  persistFileName: boolean;
  outputMode: OutputMode;
  createdAtUtc: Date;
  updatedAtUtc: Date;
}

export enum DataType {
  INTEGER = "INTEGER",
  DECIMAL = "DECIMAL",
  TEXT = "TEXT",
  DATE = "DATE",
  ENUM = "ENUM",
}

export function dataTypeToTableColumnDataType(
  dataType: DataType
): TableColumnDataType {
  switch (dataType) {
    case DataType.INTEGER:
    case DataType.DECIMAL:
      return TableColumnDataType.Number;
    case DataType.TEXT:
    case DataType.ENUM:
    case DataType.DATE:
      return TableColumnDataType.Date;
  }

  return TableColumnDataType.Text;
}

export interface DataTableColumn {
  dataTableColumnId: string;
  dataTableId: string;
  name: string;
  dataType: DataType;
  index: number;
  isKey: boolean;
  isUnique: boolean;
  isRequired: boolean;
  allowedValues: string | null;
  customValue: string;
  aliases: string[];
  script: string | null;
  createdAtUtc: Date;
  updatedAtUtc: Date;
}

export interface DataTableUploadEntry {
  dataTableEntryId: string;
  dataTableId: string;
  dataTableUploadId: string;
  key: string;
  value: {
    [key: string]: string;
  };
  rowNumber: number;
  createdAtUtc: Date;
  updatedAtUtc: Date;
}
