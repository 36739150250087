import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Icon,
  useToast,
} from "@chakra-ui/react";
import {
  DataTableUpload,
  DataTableUploadStatus,
  updateUploadEntries,
  validateUpload,
  abandonUpload,
  getUploadById,
} from "../../../api/data-table-uploads-client";
import { DataTable, DataType } from "../../../api/data-tables-client";
import styles from "./UploadTabs.module.scss";
import SelectDataSource from "./SelectDataSource";
import { useState } from "react";
import ReviewAndFinalize from "./ReviewAndFinalize";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { createUpload } from "../../../mutate/uploads";
import { User, UserWithRoles } from "../../../api/users-client";
import UploadFile from "./UploadFile";
import {
  MdCheckCircle,
  MdOutlineUploadFile,
  MdOutlineAssignment,
  MdOutlineEditCalendar,
} from "react-icons/md";
import { waitUntil } from "../../../util/condition";

export default function UploadTabs({
  user,
  dataTableId,
  dataTable,
}: {
  user: UserWithRoles;
  dataTableId: string;
  dataTable: DataTable;
}) {
  const { dataTableUploadId } = useParams();

  const [upload, setUpload] = useState<DataTableUpload | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // file related state
  const [newUploadFile, setNewUploadFile] = useState<File | null>(null);
  const [newUploadDataTables, setNewUploadDataTables] = useState<
    DataTable[] | null
  >(null);
  const [newUploadFileColumnTypes, setNewUploadFileColumnTypes] = useState<{
    [key: string]: DataType;
  }>({});
  const [newUploadKey, setNewUploadKey] = useState<string | null>(null);
  const [newUploadFileRows, setNewUploadFileRows] = useState<any[]>([]);

  const toast = useToast();
  const successfulUpload = toast.bind(null, {
    description: "Your upload has completed successfully",
    status: "success",
    duration: 3000,
    isClosable: true,
  });

  if (dataTableUploadId && activeTab !== 2) {
    setActiveTab(2);
  }
  if (
    (dataTableUploadId == null || dataTableUploadId === "") &&
    activeTab === 2
  ) {
    setActiveTab(0);
  }

  return (
    <Tabs isFitted index={activeTab}>
      <TabList>
        <Tab>
          <Icon
            className={styles.tabIcon}
            as={activeTab === 0 ? MdOutlineUploadFile : MdCheckCircle}
          />
          Upload a File
        </Tab>
        {/* <Tab>Select Header Row</Tab> */}
        {/* <Tab>Map Columns</Tab> */}
        <Tab>
          <Icon
            className={styles.tabIcon}
            as={activeTab <= 1 ? MdOutlineAssignment : MdCheckCircle}
          />
          Preview Data
        </Tab>
        <Tab>
          <Icon
            className={styles.tabIcon}
            as={activeTab <= 2 ? MdOutlineEditCalendar : MdCheckCircle}
          />
          Review and Finalise
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel px={0}>
          <UploadFile
            user={user}
            dataTableId={dataTableId}
            dataTable={dataTable}
            newUploadDataTables={newUploadDataTables}
            setNewUploadFile={(f) => {
              setNewUploadFile(f);
              setActiveTab(1);
            }}
            setNewUploadDataTables={setNewUploadDataTables}
            setNewUploadFileColumnTypes={setNewUploadFileColumnTypes}
            setNewUploadKey={setNewUploadKey}
            setNewUploadFileRows={setNewUploadFileRows}
            onCancel={() => {
              navigate(`/datasources/${dataTableId}`);
            }}
          />
        </TabPanel>
        <TabPanel px={0}>
          {newUploadDataTables != null && (
            <SelectDataSource
              dataTable={dataTable}
              isLoading={isLoading}
              onCreate={async (dataTable, uploadMode) => {
                setIsLoading(true);

                try {
                  const upload = await createUpload(
                    dataTable,
                    newUploadFile!,
                    newUploadKey!,
                    uploadMode,
                    toast
                  );
                  if (upload == null) {
                    return;
                  }

                  setUpload(upload);

                  if (upload?.status === DataTableUploadStatus.PENDING_EXPORT) {
                    queryClient.invalidateQueries("uploads");
                    setNewUploadFile(null);
                    setActiveTab(0);
                    successfulUpload();
                  } else {
                    navigate(
                      `/datasources/${dataTableId}/upload/${upload?.dataTableUploadId}`
                    );
                    setActiveTab(2);
                  }
                } catch (e) {
                  console.log("failed to create and import upload", e);
                } finally {
                  setIsLoading(false);
                }
              }}
              uploadFileRows={newUploadFileRows}
              uploadFileColumnTypes={newUploadFileColumnTypes}
              onCancel={() => {
                setNewUploadFile(null);
                setActiveTab(0);
              }}
            />
          )}
        </TabPanel>
        <TabPanel px={0}>
          <ReviewAndFinalize
            key={upload?.dataTableUploadId}
            isLoading={isLoading}
            dataTable={dataTable}
            newUpload={upload}
            onContinue={async (updatedEntries) => {
              if (upload == null) {
                return;
              }

              setIsLoading(true);

              const uploadId = upload.dataTableUploadId!!;

              try {
                if (Object.keys(updatedEntries).length > 0) {
                  await updateUploadEntries(
                    uploadId,
                    Object.keys(updatedEntries).map((entryId) => ({
                      entryId: entryId,
                      value: updatedEntries[entryId],
                    }))
                  );
                }

                await validateUpload(uploadId);

                await waitUntil(async () => {
                  const upload = await getUploadById(uploadId);
                  return (
                    (upload?.status &&
                      [
                        DataTableUploadStatus.PENDING_EXPORT,
                        DataTableUploadStatus.FAILED,
                        DataTableUploadStatus.CANCELLED,
                      ].indexOf(upload?.status) !== -1) ||
                    false
                  );
                }, 1000);

                const updatedUpload = await getUploadById(uploadId);

                if (
                  updatedUpload?.status === DataTableUploadStatus.PENDING_EXPORT
                ) {
                  queryClient.invalidateQueries("uploads");
                  setTimeout(() => {
                    successfulUpload();
                    setNewUploadFile(null);
                    navigate(`/datasources/${dataTableId}/upload`);
                  }, 200);
                  return;
                }

                setUpload(updatedUpload);
              } catch (e) {
                console.log("failed to update upload entries and validate", e);
              } finally {
                setIsLoading(false);
              }
            }}
            onCancel={async (u: DataTableUpload | null) => {
              if (u == null || u.status === DataTableUploadStatus.CANCELLED) {
                navigate(`/datasources/${dataTableId}/upload`);
                return;
              }
              setIsLoading(true);

              try {
                await abandonUpload(u.dataTableUploadId!!);
                setUpload(null);
                navigate(`/datasources/${dataTableId}/upload`);
              } catch (e) {
                console.log("failed to abandon upload", e);
              } finally {
                setIsLoading(false);
              }
            }}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
