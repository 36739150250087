import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  Connection,
  ConnectionDataTable,
  ConnectionType,
  createConnection,
  deleteConnection,
  getConnection,
  getConnections,
  updateConnection,
} from "../api/connections-client";
import { RoleEntityType, getMe } from "../api/users-client";
import BlobConnectionForm from "../components/BlobConnectionForm";
import DestinationBox from "../components/DestinationBox";
import S3ConnectionForm from "../components/S3ConnectionForm";
import { uuidv4 } from "../util/uuid";

import styles from "./MutateConnection.module.scss";
import { DeleteSimpleDialog } from "../components/SimpleDialog";
import { canWrite, canDelete } from "../util/permission";
import { getTenantFeatures } from "../api/tenant-client";
import { Features, isFeatureEnabled } from "../util/tenant";
import EmailConnectionForm from "../components/EmailConnectionForm";
import FormInput from "../components/form/FormInput";

function MutateConnection() {
  const { connectionId } = useParams();
  const isCreate = connectionId == null;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState<ConnectionType | null>(null);
  const [host, setHost] = useState("");
  const [region, setRegion] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [dataSourceName, setDataSourceName] = useState("");
  const [protocol, setProtocol] = useState<string | null>(null);
  const [valid, setValid] = useState(false);
  const [createdAt, setCreatedAt] = useState(new Date());
  const [dataTables, setDataTables] = useState<ConnectionDataTable[]>([]);
  const [errMsg, setErrMsg] = useState("");

  const [loading, setLoading] = useState(
    connectionId != null && connectionId !== ""
  );
  const { data: user, isLoading: isLoadingUser } = useQuery("me", getMe);

  const { data: features, isLoading: isLoadingFeatures } = useQuery(
    "features",
    getTenantFeatures
  );

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useEffect(
    () => {
      if (connectionId) {
        getConnection(connectionId).then((conn) => {
          unstable_batchedUpdates(() => {
            setProtocol(conn?.dataSourceProtocol || protocol);
            setDataSourceName(conn?.dataSourceName || dataSourceName);
            setPassword(conn?.dataSourcePassword || password);
            setUsername(conn?.dataSourceUsername || username);
            setRegion(conn?.dataSourceRegion || region);
            setHost(conn?.dataSourceHost || host);
            setType(conn?.connectionType || type);
            setDescription(conn?.description || description);
            setName(conn?.name || name);
            setCreatedAt(conn?.createdAtUtc || createdAt);
            setDataTables(conn?.dataTables || []);
            setLoading(false);
          });
          return conn;
        });
      }
    },
    /* eslint-disable */
    []
  );

  const deleteMutation = useMutation(
    (connectionId: string) => {
      return deleteConnection(connectionId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("connections");
        navigate("/connections");
      },
    }
  );

  const mutation = useMutation(
    (newConn: Connection) => {
      if (connectionId) {
        return updateConnection(newConn);
      }
      return createConnection(newConn);
    },
    {
      onSuccess: (data, variables, context) => {
        navigate("/connections");
      },
    }
  );

  if (loading) {
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  return (
    <Flex className={styles.mutateContainer}>
      <Heading className={styles.title}>
        {connectionId ? "Update connection" : "Create connection"}
      </Heading>
      <FormInput 
        id="name"
        label="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        isRequired
      />
      <FormControl>
        <FormLabel className={styles.label} htmlFor="description">
          Description
        </FormLabel>
        <Input
          id="description"
          className={styles.input}
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </FormControl>
      <FormControl>
        <h2 className={styles.subHeading}>Destination</h2>
        <Flex className={styles.destinations}>
          <DestinationBox
            className={styles.destinationBox}
            imageUrl="/s3.webp"
            name={"Amazon S3"}
            id="amazon-s3-dest"
            currentType={type}
            type={ConnectionType.S3}
            setDestination={setType}
          />
          <DestinationBox
            className={styles.destinationBox}
            imageUrl="/blob.png"
            name={"Azure Blob"}
            currentType={type}
            id="azure-blob-dest"
            type={ConnectionType.Blob}
            setDestination={setType}
          />
          {features &&
            isFeatureEnabled(features, Features.EMAIL_CONNECTION) && (
              <DestinationBox
                className={styles.destinationBox}
                imageUrl="/email.svg"
                name={"Email"}
                currentType={type}
                id="email-dest"
                type={ConnectionType.Email}
                setDestination={setType}
              />
            )}
        </Flex>

        <Flex className={styles.destinationForm}>
          {type === ConnectionType.S3 && (
            <S3ConnectionForm
              endpoint={host}
              setEndpoint={setHost}
              accessKeyId={username}
              setAccessKeyId={setUsername}
              secretAccessKey={password}
              bucketName={dataSourceName}
              region={region}
              connectionId={connectionId || null}
              setSecretAccessKey={setPassword}
              setBucketName={setDataSourceName}
              setRegion={setRegion}
              setValid={setValid}
            />
          )}
          {type === ConnectionType.Blob && (
            <BlobConnectionForm
              accountName={username}
              accountKey={password}
              endpointSuffix={host}
              defaultEndpointsProtocol={protocol}
              containerName={dataSourceName}
              placeholderConnString={
                connectionId ? "************************************" : null
              }
              connectionId={connectionId || null}
              setAccountName={setUsername}
              setAccountKey={setPassword}
              setEndpointSuffix={setHost}
              setContainerName={setDataSourceName}
              setDefaultEndpointsProtocol={setProtocol}
              setValid={setValid}
            />
          )}
          {type === ConnectionType.Email && (
            <EmailConnectionForm
              email={host}
              subject={username}
              body={dataSourceName}
              setEmail={setHost}
              setSubject={setUsername}
              setBody={setDataSourceName}
              setValid={setValid}
            />
          )}
        </Flex>
      </FormControl>

      {errMsg && (
        <Alert status="error" mb={2}>
          <AlertIcon />
          <AlertDescription>{errMsg}</AlertDescription>
        </Alert>
      )}

      <Flex className={styles.actionContainer}>
        {canWrite(
          user,
          RoleEntityType.Connection,
          connectionId || ""
        ) && (
            <Button
              isDisabled={!valid || name === "" || type == null}
              colorScheme="primaryScheme"
              color="white"
              className={styles.btn}
              isLoading={mutation.isLoading}
              onClick={() =>
                mutation.mutate({
                  connectionId: connectionId || uuidv4(),
                  name: name,
                  description: description,
                  dataSourceHost: host,
                  dataSourceUsername: username,
                  dataSourcePassword: password,
                  dataSourceName: dataSourceName,
                  dataSourceRegion: region,
                  dataSourceProtocol: protocol,
                  connectionType: type!!,
                  dataTables: dataTables,
                  createdAtUtc: createdAt,
                  updatedAtUtc: new Date(),
                })
              }
            >
              {connectionId ? "Update" : "Create"}
            </Button>
          )}

        {!isCreate && connectionId && (
          <DeleteSimpleDialog
            title="Delete connection"
            body="Are you sure you want to delete this connection?"
            canDelete={
              !isCreate &&
              canDelete(
                user,
                RoleEntityType.Connection,
                connectionId || ""
              )
            }
            isLoading={deleteMutation.isLoading}
            onAttempt={() => {
              if (dataTables.length > 0) {
                setErrMsg(
                  `Please remove all dependant data sources before deleting this connection: [${dataTables
                    .map((dt) => dt.name)
                    .join(", ")}]`
                );
                return false;
              }
              return true;
            }}
            onDelete={() => deleteMutation.mutate(connectionId)}
          />
        )}
      </Flex>
    </Flex>
  );
}

MutateConnection.defaultProps = {
  conn: null,
};

export default MutateConnection;
