import { FormControl, FormLabel, Input } from "@chakra-ui/react";

import styles from "./FormInput.module.scss";
import React from "react";

type FormInputProps = {
    id: string
    label: string
    type: React.HTMLInputTypeAttribute
    value: any
    placeholder?: string
    onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined
    isRequired: boolean
}

function FormInput({
    id,
    label,
    type,
    value,
    placeholder,
    onChange,
    isRequired,
}: FormInputProps) {
    return (
        <FormControl id={id} isRequired={isRequired}>
            <FormLabel className={styles.label}>{label}</FormLabel>
            <Input
                className={styles.input}
                type={type}
                value={value}
                onChange={onChange}
                placeholder={placeholder || label}
            />
        </FormControl>
    )
}

FormInput.defaultProps = {
    isRequired: false,
    type: "text",
    onChange: undefined,
};
export default FormInput;
