import {
  Button,
  Flex,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Connection, getConnections } from "../api/connections-client";
import {
  DataTableColumn,
  getDataTable,
  OutputType,
  getDataTableDataLink,
  getDataTableCurrentEntries,
  getDataTableUploadHistory,
  getDataTableTemplateLink,
  dataTypeToTableColumnDataType,
} from "../api/data-tables-client";
import { RoleEntityType, getMe } from "../api/users-client";
import {
  PaginatedSimpleTable,
  TableColumn,
  TableColumnDataType,
} from "../components/SimpleTable";

import styles from "./ViewDataSource.module.scss";
import { MdCloudDownload, MdOutlineBorderColor } from "react-icons/md";
import BreadcrumbBar from "../components/BreadcrumbBar";
import { DestinationInfoBox } from "../components/DestinationBox";
import {
  getUploadProcessedDownloadLink,
  getUploadRawDownloadLink,
} from "../api/data-table-uploads-client";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {
  canDelete,
  canWrite,
  canUpload,
  canView,
  canRead,
} from "../util/permission";
import { PaginatedInfo } from "../api/client";
import { capitalizeFirstLetter } from "../util/strings";

function downloadUploadRowAction(row) {
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            isActive={isOpen}
            as={Button}
            rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          >
            <Icon as={MdCloudDownload} boxSize={6} />
          </MenuButton>
          <MenuList>
            {row.rawProcessedFileKey && (
              <MenuItem
                onClick={() =>
                  getUploadRawDownloadLink(row.uploadId).then((r) => {
                    if (!r) {
                      return;
                    }

                    window.open(r.url, "_blank");
                  })
                }
              >
                Raw
              </MenuItem>
            )}
            {row.processedFileKey && (
              <MenuItem
                onClick={() =>
                  getUploadProcessedDownloadLink(row.uploadId).then((r) => {
                    if (!r) {
                      return;
                    }

                    window.open(r.url, "_blank");
                  })
                }
              >
                Processed
              </MenuItem>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
}

function ViewDataSource() {
  const { dataTableId } = useParams();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [columns, setColumns] = useState<DataTableColumn[]>([]);
  const [connectionId, setConnectionId] = useState("");
  const [outputPath, setOutputPath] = useState("");
  const [outputType, setOutputType] = useState<OutputType>(OutputType.CSV);
  const [loading, setLoading] = useState(
    dataTableId != null && dataTableId !== ""
  );
  const [dataColumns, setDataColumns] = useState<TableColumn[]>([]);

  //TODO autocomplete
  const connections = useQuery("connections", () => getConnections(0, 1000));
  const hasConnections = (connections?.data?.results || []).length > 0;

  const { data: user, isLoading: isLoadingUser } = useQuery("me", getMe);
  const [connection, setConnection] = useState<Connection | null>(null);
  const [pageInfo, setPageInfo] = useState<PaginatedInfo | null>(null);
  const [uploadPageInfo, setUploadPageInfo] = useState<PaginatedInfo | null>(
    null
  );

  function getDataTableEntries(pageNo, pageSize) {
    return getDataTableCurrentEntries(dataTableId!, pageNo, pageSize);
  }

  function getDataTableUploadHistoryList(pageNo, pageSize) {
    return getDataTableUploadHistory(dataTableId!, pageNo, pageSize);
  }

  const navigate = useNavigate();

  useEffect(
    () => {
      if (dataTableId) {
        getDataTable(dataTableId).then((dataTable) => {
          unstable_batchedUpdates(() => {
            setName(dataTable?.name || name);
            setDescription(dataTable?.description || description);
            setColumns(dataTable?.columns || columns);
            setConnectionId(dataTable?.connectionId || connectionId);
            setOutputPath(dataTable?.outputPath || outputPath);
            setOutputType(dataTable?.outputType || outputType);
            setConnection(dataTable?.connection || connection);
            setLoading(false);

            setDataColumns(
              (dataTable?.columns || columns).map((col) => ({
                id: col.name,
                name: col.name,
                dataType: dataTypeToTableColumnDataType(col.dataType),
              }))
            );
          });
        });
      }
    },
    /* eslint-disable */
    []
  );

  if (connectionId === "" && hasConnections) {
    setConnectionId(connections.data!!.results[0].connectionId);
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  if (loading) {
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  return (
    <Flex className={styles.mutateContainer}>
      <BreadcrumbBar
        list={[{ name: "Data sources", to: "/datasources" }, { name }]}
      />

      <Flex justify="space-between" mb={8}>
        <Heading as="h1" size="lg" p={0} m={0}>
          {name}
        </Heading>

        {dataTableId && (
          <Stack spacing={4} direction="row" align="center">
            {canUpload(
              user,
              RoleEntityType.DataTable,
              dataTableId
            ) && (
                <Tooltip
                  hasArrow
                  label="Cannot upload, missing defined data structure."
                  bg="red.600"
                  isDisabled={columns.length > 0}
                >
                  <Button
                    onClick={() => navigate(`/datasources/${dataTableId}/upload`)}
                    w={130}
                    fontWeight="normal"
                    variant="outline"
                    size="md"
                    isDisabled={columns.length === 0}
                  >
                    <Icon as={MdOutlineBorderColor} boxSize={6} mr={1} />
                    Upload
                  </Button>
                </Tooltip>
              )}
            {canWrite(
              user,
              RoleEntityType.DataTable,
              dataTableId || ""
            ) && (
                <Button
                  onClick={() => navigate(`/datasources/${dataTableId}/edit`)}
                  w={130}
                  fontWeight="normal"
                  variant="outline"
                  size="md"
                >
                  <Icon as={MdOutlineBorderColor} boxSize={6} mr={1} />
                  Edit
                </Button>
              )}
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    isActive={isOpen}
                    as={Button}
                    rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  >
                    <Flex alignItems="center">
                      <Icon as={MdCloudDownload} boxSize={6} mr={1} />
                      Download
                    </Flex>
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={() =>
                        getDataTableTemplateLink(dataTableId).then((r) => {
                          if (!r) {
                            return;
                          }

                          window.open(r.url, "_blank");
                        })
                      }
                    >
                      Template
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        getDataTableDataLink(dataTableId).then((r) => {
                          if (!r) {
                            return;
                          }

                          window.open(r.url, "_blank");
                        })
                      }
                    >
                      Data
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </Stack>
        )}
      </Flex>

      <Flex flexDirection="column" mb={8}>
        <Heading as="h2" size="sm" p={0}>
          Description
        </Heading>
        {description || <span className={styles.na}>No description</span>}
      </Flex>

      <Flex flexDirection="column" mb={8}>
        <Flex justify="space-between" mb={4}>
          <Heading alignItems="center" as="h2" size="sm" p={0} m={0}>
            Data
          </Heading>
          {/* <span>Last update: </span> */}
        </Flex>
        {pageInfo && pageInfo.count === 0 && dataColumns.length === 0 && (
          <span className={styles.na}>No data structures set up yet</span>
        )}
        {
          <PaginatedSimpleTable
            setPaginatedInfo={setPageInfo}
            className={styles.table}
            columns={dataColumns}
            getData={getDataTableEntries}
            dataName={`datatables_entires_${dataTableId}`}
            transform={(data) =>
              (data?.results || [null, null, null]).map((item) => {
                let row = {};
                for (let col of dataColumns) {
                  if (item && item.value) {
                    row[col.id] = item?.value[col.id];
                  } else {
                    row[col.id] = "-";
                  }
                }
                return row;
              })
            }
            editable={false}
            refreshInterval={10000}
            addBtn={
              pageInfo &&
              pageInfo.count === 0 &&
              dataColumns.length > 0 && (
                <Flex className={styles.na}>No data uploaded</Flex>
              )
            }
          />
        }
      </Flex>

      <Flex flexDirection="column" mb={6}>
        <Heading as="h2" size="sm" p={0} mb={4}>
          Update history
        </Heading>
        {
          <PaginatedSimpleTable
            setPaginatedInfo={setUploadPageInfo}
            className={styles.update_table}
            columns={[
              {
                id: "name",
                name: "Name",
                dataType: TableColumnDataType.Text,
              },
              {
                id: "mode",
                name: "Mode",
                dataType: TableColumnDataType.Text,
              },
              {
                id: "date",
                name: "Date",
                dataType: TableColumnDataType.Date,
              },
              {
                id: "status",
                name: "Status",
                dataType: TableColumnDataType.Text,
              },
            ]}
            getData={getDataTableUploadHistoryList}
            dataName={`datatables_uploads_${dataTableId}`}
            transform={(data) =>
              (data?.results || [null, null, null]).map((item) => {
                let row = {
                  uploadId: item?.dataTableUploadId || "-",
                  name: !!item
                    ? `${item.uploadUser.firstName} ${item.uploadUser.lastName}`
                    : "-",
                  date: item?.updatedAtUtc || "-",
                  status: capitalizeFirstLetter(item?.status || "-"),
                  mode: capitalizeFirstLetter(item?.uploadMode || "-"),
                  rawProcessedFileKey: item?.rawProcessedFileKey,
                  processedFileKey: item?.processedFileKey,
                };
                return row;
              })
            }
            editable={false}
            refreshInterval={10000}
            addBtn={
              uploadPageInfo &&
              uploadPageInfo.count === 0 && (
                <Flex className={styles.na}>No update history yet</Flex>
              )
            }
            actions={(row) => {
              return row.rawProcessedFileKey || row.processedFileKey
                ? downloadUploadRowAction(row)
                : null;
            }}
            colWidths={[null, null, null, null, 10]}
          />
        }
      </Flex>

      {connection &&
        canRead(
          user,
          RoleEntityType.Connection,
          connection.connectionId
        ) && (
          <Flex flexDirection="column" mb={6}>
            <Heading as="h2" size="sm" p={0} mb={4}>
              Destination
            </Heading>
            {connection && (
              <DestinationInfoBox
                type={connection.connectionType}
                name={connection.name}
                description={connection.description || ""}
                path={outputPath}
                onClick={() =>
                  navigate(`/connections/edit/${connection.connectionId}`)
                }
              />
            )}
          </Flex>
        )}
    </Flex>
  );
}

export default ViewDataSource;
