import {
  Flex,
  Image,
  Button,
  Heading,
  Tooltip,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { MdChevronLeft, MdChevronRight, MdLogout, MdSettings } from "react-icons/md";

import { useState } from "react";

import styles from "./NavBar.module.scss";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { User, UserPermissions, UserWithRoles } from "../api/users-client";

import {
  mdiAccountGroupOutline,
  mdiFileDocumentOutline,
  mdiSourceBranch,
  mdiUploadMultiple,
} from "@mdi/js";
import * as Mdi from "@mdi/react";
import { canView } from "../util/permission";
import { Role } from "../api/roles-client";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

function SimpleNavItem({
  icon,
  text,
  activeKey,
  expanded,
  setActiveKey,
  link,
  className,
}: {
  icon: any;
  text: string;
  activeKey: string;
  expanded: boolean;
  setActiveKey: (key: string) => void;
  link: string;
  className: string | null;
}) {
  const key = text.toLowerCase().replaceAll(" ", "");
  return (
    <Link to={link} className={className || undefined}>
      <Tooltip hasArrow label={text}>
        <Flex
          key={key}
          className={classNames(
            styles.navItem,
            styles.link,
            activeKey === key ? styles.navItemActive : null
          )}
          onClick={() => setActiveKey(key)}
        >
          {typeof icon === "string" ? (
            <Mdi.Icon
              className={classNames(
                styles.icon,
                activeKey === key ? styles.iconActive : null
              )}
              path={icon}
            />
          ) : (
            <Icon
              className={classNames(
                styles.icon,
                activeKey === key ? styles.iconActive : null
              )}
              as={icon}
            />
          )}

          <div
            className={classNames(
              styles.navItemText,
              activeKey === key ? styles.navItemTextActive : null,
              !expanded ? styles.navItemTextHidden : null
            )}
          >
            {text}
          </div>
        </Flex>
      </Tooltip>
    </Link>
  );
}

SimpleNavItem.defaultProps = { className: null };


function NavBar({
  children,
  user,
  signOut,
}: {
  children: any;
  user: UserWithRoles | null | undefined;
  signOut: () => void;
}) {
  const location = useLocation();

  const [expanded, setExpanded] = useState(true);
  const [activeKey, setActiveKey] = useState(location.pathname.split("/")[1]);

  if (location.pathname.split("/")[1] !== activeKey) {
    setActiveKey(location.pathname.split("/")[1])
  }

  const navigate = useNavigate()

  return (
    <Flex className={styles.page}>
      <Flex className={classNames(styles.sideNav)}>
        <Flex
          className={classNames(
            styles.sideNavContainer,
            !expanded ? styles.sideNavContainerClosed : styles.sideNavContainerOpen
          )}
        >
          <Button
            onClick={() => setExpanded(!expanded)}
            className={styles.sideNavToggle}>
            <Icon as={expanded ? MdChevronLeft : MdChevronRight} />
          </Button>
          <Flex className={styles.navItem} gap="2" alignItems="center">
            <Image
              src="/referencr-logo-square.svg"
              className={styles.logoImg}
              alt="referencr logo"
            />
            <Heading className={classNames(
              styles.heading,
              !expanded ? styles.navItemTextHidden : null
            )}>referencr</Heading>

          </Flex>
          {user != null && canView(user, user.permissions?.canReadConnections || false, "") && (
            <SimpleNavItem
              icon={mdiSourceBranch}
              text="Connections"
              activeKey={activeKey}
              expanded={expanded}
              setActiveKey={setActiveKey}
              link="/connections"
            />
          )}
          {user != null && canView(user, user.permissions?.canReadDataTables || false, "") && (
            <SimpleNavItem
              icon={mdiFileDocumentOutline}
              text="Data Sources"
              activeKey={activeKey}
              expanded={expanded}
              setActiveKey={setActiveKey}
              link="/datasources"
            />
          )}
          {user != null && canView(user, user.permissions?.canReadUsers || false, "") && (
            <SimpleNavItem
              icon={mdiAccountGroupOutline}
              text="Users"
              activeKey={activeKey}
              expanded={expanded}
              setActiveKey={setActiveKey}
              link="/users"
            />
          )}
          {user != null && canView(user, user.permissions?.canReadRoles || false, "") && (
            <SimpleNavItem
              icon={mdiAccountGroupOutline}
              text="Roles"
              activeKey={activeKey}
              expanded={expanded}
              setActiveKey={setActiveKey}
              link="/roles"
            />
          )}
        </Flex>

        <Flex className={styles.container}>
          <Flex className={styles.header}>
            <Flex className={styles.userContainer}>
              <Menu>
                {({ isOpen }) => (
                  <>
                    <MenuButton bg="white" isActive={isOpen} as={Button} rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}>
                      <Flex alignItems="center">
                        {user ? `${user.firstName} ${user.lastName}` : "Profile"}
                      </Flex>
                    </MenuButton>
                    <MenuList>
                      {/* <MenuItem
                        onClick={() => navigate("/users/settings")}
                      >
                        <Icon
                          as={MdSettings}
                          mr={2}
                        />Settings
                      </MenuItem> */}
                      <MenuItem
                        onClick={() => signOut()}
                      >
                        <Icon
                          as={MdLogout}
                          mr={2}
                        />Logout
                      </MenuItem>
                    </MenuList>
                  </>
                )}
              </Menu>
            </Flex>
          </Flex>
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default NavBar;
