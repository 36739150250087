import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  createUser,
  deleteUser,
  getMe,
  getUser,
  RoleEntityType,
  updateUser,
  User,
  UserWithRoles,
} from "../api/users-client";
import { uuidv4 } from "../util/uuid";

import styles from "./MutateUser.module.scss";
import { DeleteSimpleDialog } from "../components/SimpleDialog";
import { Permission, canCreate, canDelete } from "../util/permission";
import { Role, SimpleRole, getSimpleRoles } from "../api/roles-client";
import SimpleTable, {
  TableColumn,
  TableColumnDataType,
} from "../components/SimpleTable";

type Selectable<T> = T & {
  selected: boolean;
};

function addPermission(current: string, toAdd: string): string {
  const perms = current.split("+");
  if (perms.indexOf(toAdd) !== -1) return current;

  return perms.concat(toAdd).join("+");
}

function removePermission(current: string, toRemove: string): string {
  const perms = current.split("+");
  if (perms.indexOf(toRemove) === -1) return current;

  return perms.filter((p) => p !== toRemove).join("+");
}

function MutateUser() {
  const { userId } = useParams();

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [simpleRoleList, setSimpleRoleList] = useState<
    Selectable<SimpleRole>[]
  >([]);

  const [createdAt, setCreatedAt] = useState(new Date());
  const [loading, setLoading] = useState(userId != null && userId !== "");

  const [errMsg, setErrMsg] = useState("");

  const { data: user, isLoading: isLoadingUser } = useQuery("me", getMe);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useEffect(
    () => {
      if (userId) {
        getUser(userId).then((user) => {
          unstable_batchedUpdates(() => {
            setEmail(user?.email || email);
            setFirstName(user?.firstName || firstName);
            setLastName(user?.lastName || lastName);
            setCreatedAt(user?.createdAtUtc || createdAt);
            setLoading(false);
          });
          return user;
        });
      } else {
        getSimpleRoles().then((roles) => {
          if (roles) {
            setSimpleRoleList(
              roles.map((item) => ({
                ...item,
                selected: false,
              }))
            );
          }
        });
      }
    },
    /* eslint-disable */
    []
  );

  const mutation = useMutation(
    (newUser: User) => {
      if (newUser.email === "") {
        throw Error("Email is required");
      }
      if (userId) {
        return updateUser(newUser);
      }

      const selectedRoles = simpleRoleList.filter(
        (item) => item.selected === true
      );
      if (!selectedRoles) {
        throw Error("Please select atleast one role");
      }
      const newUserWithRoles = {
        ...newUser,
        roles: selectedRoles.map((e) => ({
          roleId: e.roleId,
          name: e.name,
        })),
      };
      return createUser(newUserWithRoles);
    },
    {
      onSuccess: (data, variables, context) => {
        navigate("/users");
      },
      onError: (error: any, variables, context) => {
        setErrMsg(error.message);
      },
    }
  );

  const deleteMutation = useMutation(
    (userId: string) => {
      return deleteUser(userId);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("users");
        navigate("/users");
      },
    }
  );

  if (loading) {
    return (
      <Flex className={styles.spinnerContainer}>
        <Spinner
          colorScheme="primaryScheme"
          size="xl"
          label="Loading..."
          speed="0.6s"
          thickness="4px"
        />
      </Flex>
    );
  }

  return (
    <Flex className={styles.mutateContainer}>
      <Heading className={styles.title}>
        {userId ? "Update user" : "Create user"}
      </Heading>
      <FormControl>
        <FormLabel className={styles.label} htmlFor="email">
          Email
        </FormLabel>
        <Input
          id="email"
          className={styles.input}
          placeholder="Email"
          value={email}
          disabled={userId != null}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FormLabel className={styles.label} htmlFor="firstName">
          First Name
        </FormLabel>
        <Input
          id="firstName"
          className={styles.input}
          placeholder="First name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <FormLabel className={styles.label} htmlFor="lastName">
          Last Name
        </FormLabel>
        <Input
          id="lastName"
          className={styles.input}
          placeholder="Last name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />

        {simpleRoleList.length > 0 && (
          <>
            <FormLabel className={styles.label}>Assign User Role</FormLabel>
            <SimpleTable
              className={styles.table}
              data={simpleRoleList}
              columns={[
                {
                  id: "selected",
                  name: "Select",
                  dataType: TableColumnDataType.Boolean,
                },
                {
                  id: "name",
                  name: "Name",
                  dataType: TableColumnDataType.Text,
                },
              ]}
              editable={true}
              readOnlyColumns={["name"]}
              onRowClick={(row: any) => {
                setSimpleRoleList(
                  simpleRoleList.map((currRow) =>
                    currRow.roleId === row.roleId
                      ? {
                        ...currRow,
                        selected: !currRow.selected,
                      }
                      : currRow
                  )
                );
              }}
              onChange={(
                _row: any,
                idx: number,
                column: TableColumn,
                value: any
              ) => {
                const row = simpleRoleList[idx];
                let newRow = {
                  ...row,
                  [column.id]: value,
                };

                setSimpleRoleList(
                  simpleRoleList.map((currRow, rowIdx) =>
                    rowIdx === idx ? newRow : currRow
                  )
                );
              }}
            />
          </>
        )}
      </FormControl>

      {errMsg.length > 0 && (
        <Flex className={styles.errorMessage}>{errMsg}</Flex>
      )}

      <Flex className={styles.actionContainer}>
        {canCreate(user, RoleEntityType.User) && (
          <Button
            disabled={email === "" || firstName === "" || lastName === ""}
            colorScheme="primaryScheme"
            color="white"
            className={styles.btn}
            isLoading={mutation.isLoading}
            onClick={() =>
              mutation.mutate({
                userId: userId || null,
                email: email,
                firstName: firstName,
                lastName: lastName,
                permissions: null,
                createdAtUtc: createdAt,
                updatedAtUtc: new Date(),
                owner: false,
              })
            }
          >
            {userId ? "Update" : "Create"}
          </Button>
        )}

        {userId && user?.userId !== userId && (
          <DeleteSimpleDialog
            title="Delete user"
            body="Are you sure you want to delete this user?"
            canDelete={canDelete(
              user,
              RoleEntityType.User,
              userId
            )}
            isLoading={deleteMutation.isLoading}
            onDelete={() => deleteMutation.mutateAsync(userId)}
          />
        )}
      </Flex>
    </Flex>
  );
}

MutateUser.defaultProps = {
  conn: null,
};

export default MutateUser;
