import { unstable_batchedUpdates } from "react-dom";
import { useMutation } from "react-query";
import {
  createDataTableUpload,
  DataTableUploadStatus,
  DataTableUploadMode,
  DataTableUpload,
  importUpload,
  validateUpload,
  getUploadById,
} from "../api/data-table-uploads-client";
import { DataTable } from "../api/data-tables-client";
import { HttpStatusCode } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/toast";
import { waitUntil } from "../util/condition";

// not a mutation function but didn't want to alter this as this is a hot fix for create & upload on create data source
export async function createUpload(
  dataTable: DataTable,
  file: File | null,
  uploadKey: string,
  uploadMode: DataTableUploadMode,
  toast: CreateToastFnReturn
): Promise<DataTableUpload | null> {
  if (file == null) {
    throw new Error("no data table or file");
  }
  const response = await createDataTableUpload({
    dataTableUploadId: null,
    fileKey: uploadKey,
    fileName: file.name,
    dataTable: null,
    dataTableId: dataTable.dataTableId,
    status: DataTableUploadStatus.PENDING,
    failureMessage: null,
    uploadMode: uploadMode,
    failureCount: 0,
    rowCount: 0,
    rowFailureCount: 0,
    createdAtUtc: new Date(),
    updatedAtUtc: new Date(),
  });

  if (response.statusCode === HttpStatusCode.Conflict) {
    toast({
      description:
        "Oops! It looks like a file with the same name has already been processed. Please use a different file name or upload in 'Overwrite' mode",
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    return null;
  } else if (response.error != null) {
    throw response.error;
  }

  const uploadId = response.data?.dataTableUploadId;
  if (uploadId != null) {
    await importUpload(uploadId);
    await waitUntil(async () => {
      const upload = await getUploadById(uploadId);
      return (
        (upload?.status &&
          [
            DataTableUploadStatus.IMPORTED,
            DataTableUploadStatus.FAILED,
            DataTableUploadStatus.CANCELLED,
          ].indexOf(upload?.status) !== -1) ||
        false
      );
    }, 1000);

    let upload = await getUploadById(uploadId);
    if (upload?.status !== DataTableUploadStatus.IMPORTED) {
      toast({
        description: "Failed to import file",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return upload;
    }

    await validateUpload(uploadId);
    await waitUntil(async () => {
      const upload = await getUploadById(uploadId);
      return (
        (upload?.status &&
          [
            DataTableUploadStatus.PENDING_EXPORT,
            DataTableUploadStatus.FAILED,
            DataTableUploadStatus.CANCELLED,
          ].indexOf(upload?.status) !== -1) ||
        false
      );
    }, 1000);

    return await getUploadById(uploadId);
  }

  return response.data;
}
